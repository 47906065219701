<template>
  <ConfirmDialog :group="data.id" />
  <Button
    icon="pi pi-ellipsis-h"
    class="p-button-text p-button-secondary"
    @click="toggle"
  />
  <!--  <pre>{{ data }}</pre>-->
  <Menu :model="items" ref="menu" :popup="true" />
</template>

<script>
import { ref } from "vue";
import { useConfirm } from "primevue/useconfirm";

export default {
  props: ["data", "updateOne"],
  setup(props) {
    const confirm = useConfirm();
    const items = ref([
      {
        label: "Activer",
        command: () => onDisable(null),
        visible: () => props.data.disabled,
      },
      {
        label: "Desactiver",
        command: () => onDisable(true),
        visible: () => !props.data.disabled,
      },
    ]);

    const menu = ref();

    const toggle = (event) => {
      menu.value.toggle(event);
    };

    const onDisable = async (value) => {
      confirm.require({
        group: props.data.id,
        header: `${props.data.disabled ? "Activer" : "Désactiver"} le produit ${
          props.data.name
        }`,
        message: "Êtes-vous certain de vouloir continuer ?",
        rejectLabel: "Retour",
        rejectClass: "p-button-secondary p-button-text",
        acceptClass: `${
          props.data.disabled ? "p-button-primary" : "p-button-danger"
        }`,
        acceptLabel: `${props.data.disabled ? "Activer" : "Désactiver"}`,
        accept: async () => {
          await props.updateOne(props.data.id, {
            ...props.data,
            disabled: value,
          });
        },
      });
    };

    return { items, menu, toggle, onDisable };
  },
};
</script>

<style lang="scss" scoped></style>
