const objectSub = (dbRef, { data, error, pending }) => {
  return (snapshot) => {
    if (snapshot.exists()) {
      data.value = snapshot.val();
      error.value = null;
    } else {
      data.value = [];
      error.value = { message: "Aucune entrée" };
    }
    pending.value = false;
  };
};

const arraySub = (dbRef, { data, error, pending }) => {
  return (snapshot) => {
    if (snapshot.exists()) {
      data.value = [];
      Object.entries(snapshot.val()).forEach((doc) => {
        const obj = {
          id: doc[0],
          ...doc[1],
        };
        if (doc[1].period) {
          obj.period = new Date(doc[1].period);
        }
        data.value.push(obj);
      });
      error.value = null;
    } else {
      data.value = [];
      error.value = { message: "Aucune entrée" };
    }
    pending.value = false;
  };
};

export { objectSub, arraySub };
