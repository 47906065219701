<template>
  <DataTable
    class="custom-datatable p-datatable-sm"
    :value="displayedArticlesArray"
    :paginator="true"
    :rows="rows"
    dataKey="id"
    v-model:filters="filters"
    filterDisplay="row"
    :loading="pending"
    v-model:selection="selectedArticles"
    ref="dt"
    sortField="category"
    :sortOrder="1"
  >
    <template #empty> Aucun article trouvé. </template>
    <template #loading> Chargement des données. </template>
    <template #footer>
      <div class="flex align-items-center justify-content-between">
        <Button
          label="Exporter (.csv)"
          icon="pi pi-external-link"
          @click="exportCSV($event)"
        ></Button>
        <Button
          :icon="!showDeleted ? 'pi pi-eye-slash' : 'pi pi-eye'"
          :label="
            !showDeleted
              ? 'Afficher les produits désactivés'
              : 'Masquer les produits désactivés'
          "
          class="p-button-sm p-button-outlined"
          @click="showDeleted = !showDeleted"
        />
        <div>
          <label class="mr-2">Afficher:</label>
          <Dropdown :options="[10, 25, 50, 100]" v-model="rows" />
        </div>
      </div>
    </template>
    <Column selectionMode="multiple" style="text-align: center; width: 48px" />
    <Column
      field="id"
      filterField="id"
      header="Code"
      :showFilterMenu="false"
      class="col-1"
    >
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          style="width: 100%"
          v-model="filterModel.value"
          @input="filterCallback"
          placeholder="Rechercher"
        />
      </template>
      <template #body="{ data }">
        <span v-if="data.disabled" class="text-danger text-sm font-bold"
          >Désactivé</span
        >
        <span v-else>{{ data.id }}</span>
      </template>
    </Column>
    <Column
      header="Catégorie"
      filterField="category"
      :showFilterMenu="false"
      class="col-1"
    >
      <template #body="{ data }">
        <span :class="data.disabled ? 'disabled' : ''">{{
          docs.categories[data.category]?.name
        }}</span>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <MultiSelect
          v-model="filterModel.value"
          @change="filterCallback()"
          :options="categoriesArray"
          optionValue="id"
          optionLabel="name"
          placeholder="Toutes"
          class="p-column-filter"
          display="chip"
          style="width: 100%"
          :filter="true"
          :loading="pending"
        >
          <template #option="slotProps">
            <div class="p-multiselect-representative-option">
              <span>{{ slotProps.option.name }}</span>
            </div>
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column
      header="Nom"
      field="name"
      filterField="name"
      :showFilterMenu="false"
      class="col"
    >
      <template #filter="{ filterModel, filterCallback }">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            class="p-column-filter"
            style="width: 100%"
            :placeholder="`Rechercher par nom`"
          />
        </span>
      </template>
      <template #body="{ data }">
        <InputText
          v-model="data.name"
          :disabled="!data.edit"
          :class="data.disabled ? 'disabled' : ''"
          style="width: 100%"
        />
      </template>
    </Column>
    <Column
      filterField="capacity"
      field="capacity"
      header="Cond."
      :showFilterMenu="false"
      class="col-1"
    >
      <template #body="{ data }">
        <InputNumber
          v-model="data.capacity"
          :minFractionDigits="2"
          :disabled="!data.edit"
          locale="en-IN"
          :class="data.disabled ? 'disabled' : ''"
        />
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          type="number"
          v-model="filterModel.value"
          @input="filterCallback"
          class="p-column-filter"
          placeholder="(L)"
          style="width: 100%"
        />
      </template>
    </Column>
    <Column
      filterField="cond"
      field="cond"
      header="Livraison"
      :showFilterMenu="false"
      class="col-1"
    >
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          type="number"
          class="p-fluid"
          style="width: 100%"
          v-model="filterModel.value"
          @input="filterCallback()"
          :placeholder="`Minimum`"
        />
      </template>
      <template #body="{ data }">
        <InputNumber
          v-model="data.cond"
          :disabled="!data.edit"
          :useGrouping="false"
          :class="data.disabled ? 'disabled' : ''"
        />
      </template>
    </Column>
    <Column
      v-for="(col, i) in ratesColumns"
      :field="col.field"
      :header="`T${i}`"
      :key="col.field"
      :showFilterMenu="false"
      class="col-1"
    >
      <template #filter>
        <span class="text-sm">{{ col.header }}</span>
      </template>
      <template #body="{ data }">
        <InputNumber
          mode="currency"
          currency="EUR"
          locale="fr-FR"
          v-model="data.rates[col.field]"
          :disabled="!data.edit"
          :class="[
            data.rates[col.field] === 0 ? 'text-danger' : '',
            data.disabled ? 'disabled' : '',
          ]"
        />
      </template>
    </Column>
    <Column :showFilterMenu="false" class="col-1 justify-content-end">
      <template #filter>
        <Button
          v-tooltip="'Afficher / Masquer les produits désactivés'"
          :icon="!showDeleted ? 'pi pi-eye-slash' : 'pi pi-eye'"
          :class="
            !showDeleted
              ? 'p-button-secondary p-button-text'
              : 'p-button-danger p-button-outlined'
          "
          class="p-button-sm"
          @click="showDeleted = !showDeleted"
        />
      </template>
      <template #body="{ data }">
        <Button
          :icon="!data.edit ? 'pi pi-pencil' : 'pi pi-check'"
          class="mr-1"
          :class="[
            !data.edit
              ? 'p-button-text p-button-secondary'
              : 'p-button-success',
            data.disabled ? 'opacity-10' : '',
          ]"
          :disabled="data.disabled"
          @click="onEdit(data)"
        />

        <ContextActions :data="data" :update-one="updateOne" />
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { ref, computed } from "vue";
import { sortBy } from "lodash";
import { FilterMatchMode } from "primevue/api";

import ContextActions from "./_ContextActions";

export default {
  components: { ContextActions },
  props: {
    docs: { type: Object },
    pending: { type: Boolean },
    updateOne: { type: Function },
    ratesColumns: {
      type: Array,
      default() {
        return [];
      },
    },
    articlesArray: { type: Array },
  },
  setup(props) {
    const rows = ref(10);
    const dt = ref();

    const categoriesArray = computed(() => {
      let array = [];
      if (!props.pending) {
        Object.entries(props.docs.categories).forEach((category) => {
          array.push({ id: category[0], name: category[1].name });
        });
      }
      return sortBy(array, (a) => a.name);
    });

    const displayedArticlesArray = computed(() => {
      return props.articlesArray.filter(
        ({ disabled }) => !disabled || showDeleted.value
      );
    });

    const filters = ref({
      id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      category: { value: null, matchMode: FilterMatchMode.IN },
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      cond: {
        value: null,
        matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
      },
      capacity: {
        value: null,
        matchMode: FilterMatchMode.EQUALS,
      },
    });

    const onEdit = async (data) => {
      if (data.edit) {
        data.edit = false;
        await props.updateOne(data.id, { ...data, edit: null });
      } else {
        data.edit = true;
      }
    };

    const exportCSV = () => {
      dt.value.exportCSV();
    };

    const selectedArticles = ref([]);
    const showDeleted = ref(false);

    return {
      filters,
      categoriesArray,
      displayedArticlesArray,
      rows,
      selectedArticles,
      onEdit,
      showDeleted,
      exportCSV,
      dt,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-card.table) {
  .p-card-body {
    padding: 0;
  }
  .p-card-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0.75rem;
  }
}

.custom-datatable {
  ::v-deep(input) {
    width: auto;
    &:disabled {
      background: none;
      color: inherit;
      border-color: transparent;
      opacity: 1;
    }
  }
}
</style>
