<template>
  <!--  <div class="flex justify-content-between align-items-center">-->
  <!--    <Button-->
  <!--      label="Mettre à jour la mercuriale (catalogue Easilys)"-->
  <!--      icon="pi"-->
  <!--      class="mb-3"-->
  <!--      @click="updateEasilysMercurial"-->
  <!--    ></Button>-->
  <!--    <span class="text-sm"-->
  <!--      ><strong>Date dernière mise à jour :</strong> {{ updatedAt }}</span-->
  <!--    >-->
  <!--  </div>-->
  <Accordion :active-index="[0, 1]" :multiple="true">
    <AccordionTab header="Récupération des données">
      <div class="flex justify-content-between align-items-center p-3">
        <span class="text-sm"
          ><strong>Date dernière mise à jour :</strong> {{ updatedAt }}</span
        >
        <Button
          label="Mettre à jour la mercuriale (catalogue Easilys)"
          icon="pi"
          class="mb-3"
          @click="updateEasilysMercurial"
        ></Button>
      </div>
    </AccordionTab>
    <AccordionTab header="Mércuriale Easilys">
      <BasePending v-if="pending" />
      <MercurialDataTable
        v-else
        :docs="docs"
        :articles-array="articlesArray"
        :pending="pending"
        :update-one="updateOne"
      />
    </AccordionTab>
  </Accordion>
</template>

<script>
import { ref, onMounted } from "vue";
import useDatabase from "../../composables/useDatabase";
import { useToast } from "primevue/usetoast";

import MercurialDataTable from "./_MercurialDataTable";
import { projectDatabase, projectFunctions } from "@/firebase/config";

export default {
  components: { MercurialDataTable },
  setup() {
    const toast = useToast();
    const { getAll, updateOne } = useDatabase("mercurial-easilys/articles");
    const { getOne } = useDatabase("mercurial-easilys");
    const { docs: articlesArray } = getAll();

    const docs = ref([]);
    const pending = ref(true);
    const { doc: updatedAt } = getOne("updatedAt");

    onMounted(async () => {
      docs.value = (await projectDatabase.ref("mercurial-easilys").get()).val();
      pending.value = false;
      updatedAt.value = docs.value.updatedAt;
    });

    const updateEasilysMercurial = async () => {
      pending.value = true;

      try {
        const result = await projectFunctions
          .httpsCallable("updateEasilysMercurial")
          .call();
        toast.add({
          severity: "success",
          summary: `Les données ont été recupérées avec succès. ${result.updatedAt}`,
        });
        await projectDatabase
          .ref("mercurial-easilys/updatedAt")
          .set(result.data.updatedAt);
      } catch (error) {
        toast.add({
          severity: "error",
          summary:
            "Erreur lors de l'appel à Easilys, veuillez ressayer plus tard.",
          detail: error.message,
          life: 2500,
        });
      }

      pending.value = false;
    };

    return {
      pending,
      articlesArray,
      docs,
      updateOne,
      updateEasilysMercurial,
      updatedAt,
    };
  },
};
</script>
